import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faFileAlt, faUndo, faBlog } from '@fortawesome/free-solid-svg-icons';

import './About.css'; // Create this file for the updated styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const About = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    setMenuOpen(false);
    window.location.href = event.target.href;
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (sectionTop < windowHeight - 100) {
          section.classList.add('appear');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="about-page">
      <header className="about-header">
        <h1 className="about-title">About Us</h1>
        <nav className="about-nav">
          <ul className={`about-nav-list ${menuOpen ? 'active' : ''}`}>
            <li><Link to="/" className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
            <li><Link to="/about" className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/Privacy" className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
           <li><Link to="/blogs" className={window.location.hash === '#/blogs' ? 'active' : ''}>Blog</Link></li>
            
          </ul>

          <div className={`about-hamburger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

      {/* Mobile Menu */}
      <div className={`about-mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="about-back-button" onClick={() => setMenuOpen(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="about-cut-button" onClick={toggleMenu}>
          <i className="fas fa-times"></i>
        </div>
        <li><Link to="/" onClick={handleLinkClick} className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
        <li><Link to="/about" onClick={handleLinkClick} className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
        <li><Link to="/Privacy" onClick={handleLinkClick} className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
        <li><Link to="/blogs" onClick={handleLinkClick} className={window.location.hash === '#/blogs' ? 'active' : ''}>Blog</Link></li>

      </div>

      <div className="about-content-wrapper">
        <main>
          <section>
            <h2 className="section-title">Welcome to GuruGuess</h2>
            <p className="animated-text">At GuruGuess, we're passionate about creating an engaging and competitive platform where knowledge meets excitement. Whether you're a trivia enthusiast or someone looking for a Contest, Poll and Battle way to test your knowledge, GuruGuess offers something for everyone. Our platform is designed to bring aspirants together through the thrill of Contest, Poll and Battle, where you can challenge yourself, compete with others.</p>
          </section>

          <section>
            <h2 className="section-title">Our Mission</h2>
            <p className="animated-text">Our mission is simple: to provide a Contest, Poll and Battle and fair experience for all our users. We believe that everyone has the potential to be a guru in their own right, and GuruGuess is the platform where you can showcase your skills. We aim to create a community of learners, competitors who share a love for knowledge and friendly competition.</p>
          </section>

          <section>
            <h2 className="section-title">What We Offer </h2>
            <h3 className="section-second-title">1. Contest, Poll and Battle</h3>
            <p className="animated-text">GuruGuess hosts a variety of Contest, Poll and Battle that cater to different interests and knowledge levels. From general knowledge to specialized topics, there’s always a Contest, Poll and Battle for everyone. Our Contest, Poll and Battle are designed to be both challenging and  ensuring that every participant has the chance to shine.</p>
            <ul className="mission-points">
              <li className="animated-text">Variety of Topics: We offer Contest, Poll and Battle on a wide range of subjects, including science, history, sports, entertainment, and more. Each contest is carefully crafted to test your knowledge and speed.</li>
              <li className="animated-text">Time-Bound Contest, Poll and Battle: Each Contest, Poll and Battle  is time-bound, adding an extra layer of excitement as you race against the clock to answer as many questions correctly as possible.</li>
            </ul>
            <h3 className="sectionsecond-title">2. Referral Program</h3>
            <p className="animated-text">We believe that sharing the knowledge doubles the knowledge! Our referral program is designed to reduce fee and you for bringing your friends into the GuruGuess community.</p>
            <ul className="mission-points">
              <li className="animated-text">Refer and get benefits: Invite your friends to join GuruGuess using your unique referral code. For every friend who signs up and enroll in a Contest, Poll and Battle, you'll gets benefits.</li>
              <li className="animated-text">Free Contest, Poll and Battle Entries: Referring friends can also earn you free joining into select Contest, Poll and Battle.</li>
            </ul>
            <h3 className="sectionsecond-title">4. Responsive and Speed Scoring</h3>
            <p className="animated-text">GuruGuess prides itself on maintaining a responsive and competitive environment. Our scoring system is designed to check both accuracy and speed, making sure that the best players rise to the top.</p>
            <ul className="mission-points">
              <li className="animated-text">Accuracy Matters: While correct answers are crucial, the speed at which you answer also plays a significant role in your overall score.</li>
              <li className="animated-text">Real-Time Results: Scores are calculated in real-time, allowing you to see where you stand against your competitors as the Contest, Poll and Battle progresses.</li>
            </ul>
          </section>

          <section>
            <h2 className="section-title">Responsible Attempts</h2>
            <h3 className="section-second-title">1. Attempts Responsibly</h3>
            <p className="animated-text">While we strive to provide a Contest, Poll and Battle and engaging experience, we also recognize the importance of responsible environment. The thrill of performance can be exhilarating .</p>

            <h3 className="sectionsecond-title">2. Risk Awareness</h3>
            <p className="animated-text">We want our users to be fully aware of the risks associated with excessive gaming. While our platform is designed for enjoyment, it’s crucial to maintain a balanced approach to participation.</p>
            <ul className="mission-points">
              <li className="animated-text">User Safety: Your safety and well-being are our top priorities. If you ever feel that gaming is becoming overwhelming, we urge you to take a break or seek help.</li>
            </ul>
          </section>

          <section>
            <h2 className="section-title">How It Works</h2>
            <h3 className="section-second-title">1. Simple Sign-Up</h3>
            <p className="animated-text">Getting started with GuruGuess is easy! Simply create an account using your email address, complete the verification process, and you're ready to begin your journey.</p>
            <ul className="mission-points">
              <li className="animated-text">User-Friendly Interface: Our platform is designed with simplicity in mind, ensuring that even first-time users can navigate with ease.</li>
              <li className="animated-text">Quick Verification: After signing up, verify your email to unlock full access to our Contest, Poll and Battle and features.</li>
            </ul>
            <h3 className="sectionsecond-title">2. Choose Your Contest, Poll and Battle</h3>
            <p className="animated-text">Once you're signed up, browse through our list of available Contest, Poll and Battle and choose the one that interests you the most.</p>
            <ul className="mission-points">
              <li className="animated-text">Multiple Contest, Poll and Battle: Participate in as many Contest, Poll and Battle as you like, with each offering unique challenges.</li>
              <li className="animated-text">Contest, Poll and Battle Details: Before joining, you can view Contest, Poll and Battle details, including  fees.</li>
            </ul>
            <h3 className="sectionsecond-title">3. Payment and Participation</h3>
            <p className="animated-text">To join a Contest, Poll and Battle, simply complete the payment process, and you’re in! We offer a variety of secure payment methods to make the process as seamless as possible.</p>
            <ul className="mission-points">
              <li className="animated-text">Secure Transactions: Your payment information is securely processed, and we do not store any sensitive data on our servers.</li>
              <li className="animated-text">Instant Confirmation: Once your payment is verified, you will receive instant confirmation and can start participating in the Contest, Poll and Battle.</li>
            </ul>
          </section>

          <section>
            <h2 className="section-title">Our Community</h2>
            <h3 className="section-second-title">1. Join a Growing Community</h3>
            <p className="animated-text">GuruGuess is more than just a Contest, Poll and Battle platform – it’s a community of like-minded individuals who share a passion for knowledge and competition.</p>
            <ul className="mission-points">
              <li className="animated-text">Connect with Others: Engage with fellow , share strategies, and celebrate each other’s successes.</li>
              <li className="animated-text">Stay Updated: Follow us on social media and subscribe to our newsletter for the latest updates, tips, and announcements.</li>
            </ul>
            <h3 className="sectionsecond-title">2. Support and Feedback</h3>
            <p className="animated-text">We value your feedback and are committed to providing the best possible experience. Our support team is always available to assist you with any questions or concerns.</p>
            <ul className="mission-points">
              <li className="animated-text">24/7 Support: Reach out to us anytime through our support channels, and we’ll be happy to help.</li>
              <li className="animated-text">Continuous Improvement: Your feedback helps us improve the platform, so don’t hesitate to share your thoughts.</li>
            </ul>
          </section>


          <section>
            <h2 className="section-title">Conclusion</h2>
            <p className="animated-text">we believe that knowledge is power, and we’re here to help you unleash your full potential. Whether you’re in it for the thrill of competition, the joy of learning, or the excitement of winning, GuruGuess is the place for you. So, what are you waiting for? Join us today and start your journey to becoming a quiz master!</p>
          </section>
        </main>
      </div>

      <footer className="about-footer">
        <div className="about-footer-content">
          <div className="about-footer-section contact-info">
            <p>
              <FontAwesomeIcon icon={faUndo} />
              <Link
                to="/Refund"
                className={window.location.hash === '#/Refund' ? 'active' : ''}
              >
                Cancellation and Refund
              </Link>
            </p>
            <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:guruguesss@gmail.com">guruguesss@gmail.com</a></p>
            <p><FontAwesomeIcon icon={faInstagram} /> Instagram: <a href="https://www.instagram.com/guruguesss" target="_blank" rel="noopener noreferrer">@guruguesss</a></p>
          </div>
          <div className="about-footer-section">
            <p>
              <FontAwesomeIcon icon={faFileAlt} />
              <Link
                to="/terms"
                className={window.location.hash === '#/terms' ? 'active' : ''}
              >
                Terms and Conditions
              </Link>
            </p>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:9140293443">Contact Us 9140293443</a></p>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:7460802449"> More queries:7460802449</a></p>
          </div>
        </div>
        <div className="about-footer-bottom">
          <p> Powered by : KNKN Engeenering Works pvt. ltd.</p>
          <p>© 2024 Guruguess. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default About;
