import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Mocktest.css";
import { faFileAlt, faUndo, faBlog } from '@fortawesome/free-solid-svg-icons';
import {  faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase"; // Replace with your Firebase config file path
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Mocktest = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // State for user metrics
  const [totalDownloads, setTotalDownloads] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  // State for animated numbers
  const [animatedDownloads, setAnimatedDownloads] = useState(0);
  const [animatedActiveUsers, setAnimatedActiveUsers] = useState(0);
  const [animatedTotalUsers, setAnimatedTotalUsers] = useState(0);

  // State for chart data
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    setMenuOpen(false);
    window.location.href = event.target.href;
  };

  useEffect(() => {
    const dailyRef = ref(db, "Update/Daily");

    onValue(dailyRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Data from Firebase:", data);

      if (data) {
        const downloads = data.download || 0;
        const active = data.active || 0;
        const users = data.user || 0;

        setTotalDownloads(downloads);
        setActiveUsers(active);
        setTotalUsers(users);

        setChartData({
          labels: ["Total Downloads", "Active Users", "Total Users"],
          datasets: [
            {
              label: "Metrics Overview",
              data: [downloads, active, users],
              backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
              borderColor: ["#36A2EB", "#FF6384", "#FFCE56"],
              borderWidth: 1,
            },
          ],
        });
      } else {
        console.log("No data found or path incorrect.");
        setTotalDownloads(0);
        setActiveUsers(0);
        setTotalUsers(0);
      }
    });
  }, []);

  useEffect(() => {
    const animateNumber = (target, setFunc, animatedValue) => {
      const increment = Math.ceil((target - animatedValue) / 20); // Increment gradually
      const interval = setInterval(() => {
        setFunc((prev) => {
          const newValue = prev + increment;
          if ((increment > 0 && newValue >= target) || (increment < 0 && newValue <= target)) {
            clearInterval(interval);
            return target;
          }
          return newValue;
        });
      }, 150); // Update every 150ms
    };
    animateNumber(totalDownloads, setAnimatedDownloads, animatedDownloads);
    animateNumber(activeUsers, setAnimatedActiveUsers, animatedActiveUsers);
    animateNumber(totalUsers, setAnimatedTotalUsers, animatedTotalUsers);
  }, [totalDownloads, activeUsers, totalUsers]);

  return (
    <div className="about-page">
      {/* Header */}
      <header className="about-header">
        <h1 className="about-title">Downloads</h1>
        <nav className="about-nav">
          <ul className={`mock-nav-list ${menuOpen ? "active" : ""}`}>
           <li><Link to="/" className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
                       <li><Link to="/about" className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
                       <li><Link to="/Privacy" className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
                       <li><Link to="/Contact" className={window.location.hash === '#/Contact' ? 'active' : ''}>Download </Link></li>
                     </ul>
          <div className={`mock-hamburger-menu ${menuOpen ? "active" : ""}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

 {/* Mobile Menu */}
      <div className={`blog-mobile-menu ${menuOpen ? 'open' : ''}`}>
        <div className="blog-back-button" onClick={() => setMenuOpen(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="blog-cut-button" onClick={toggleMenu}>
          <i className="fas fa-times"></i>           
        </div>
        <li><Link to="/" onClick={handleLinkClick} className={window.location.hash === '#/' ? 'active' : ''}>Home</Link></li>
            <li><Link to="/about" onClick={handleLinkClick} className={window.location.hash === '#/about' ? 'active' : ''}>About</Link></li>
            <li><Link to="/Privacy" onClick={handleLinkClick} className={window.location.hash === '#/Privacy' ? 'active' : ''}>Privacy</Link></li>
            <li><Link to="/Contact" onClick={handleLinkClick} className={window.location.hash === '#/Contact' ? 'active' : ''}>Download</Link></li>
      </div>

      {/* Summary Cards */}
      <div className="summary-cards-section">
        <div className="summary-card">
          <h3>Total Downloads</h3>
          <h2>{animatedDownloads}</h2>
          <p>Total number of downloads.</p>
        </div>
        <div className="summary-card">
          <h3>Active Users</h3>
          <h2>{animatedActiveUsers}</h2>
          <p>Currently engaged users.</p>
        </div>
        <div className="summary-card">
          <h3>Total Users</h3>
          <h2>{animatedTotalUsers}</h2>
          <p>Total number of registered users.</p>
        </div>
      </div>

      {/* Description Cards */}
      <div className="description-cards-section">
        <div className="description-card">
          <h3>Normal Contest</h3>
          <ul>
            <li>Join the Normal Contest for an exciting challenge!</li>
            <li>Test your knowledge with a series of quiz attempts and compete to score big.</li>
            <li>The higher your score, the greater your chances of winning amazing rewards!</li>
          </ul>
        </div>
        <div className="description-card">
          <h3>Poll Engagement</h3>
          <ul>
            <li>Participate in our exciting sports poll for a chance to win amazing rewards!</li>
            <li>Share your thoughts on the latest event and you could walk away with incredible prizes.</li>
            <li>Don't miss out – your opinion could be your ticket to a win!</li>
          </ul>
        </div>
        <div className="description-card">
          <h3>Join PowerPlay</h3>
          <ul>
            <li>Join a team in our PowerPlay section and contribute your knowledge by scoring high in quizzes!</li>
            <li>Your individual performance helps boost your team's total score, bringing everyone closer to big rewards.</li>
            <li>Collaborate, compete, and win together!</li>
          </ul>
        </div>
      </div>

      {/* Graph Section */}
      <div className="graph-section">
        <h2>Metrics Overview</h2>
        <div className="cards-wrapper">
          <div className="chart-container fade-in">
            <h3>Bar Chart</h3>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Daily Metrics (Bar Chart)",
                  },
                },
              }}
            />
          </div>
          <div className="chart-container slide-up">
            <h3>Line Chart</h3>
            {chartData?.datasets?.length > 0 && (
              <Bar
                data={{
                  ...chartData,
                  datasets: chartData.datasets.map((dataset) => ({
                    ...dataset,
                    type: "line",
                    borderColor: "#36A2EB",
                    backgroundColor: "rgba(54, 162, 235, 0.1)",
                    fill: true,
                  })),
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Daily Metrics (Line Chart)",
                    },
                  },
                }}
              />
            )}
          </div>
          <div className="chart-container zoom-in">
            <h3>Scatter Chart</h3>
            <Bar
              data={{
                labels: ["1", "2", "3", "4", "5"],
                datasets: [
                  {
                    label: "Scatter Dataset",
                    data: [
                      { x: 1, y: 2 },
                      { x: 2, y: 3 },
                      { x: 3, y: 5 },
                      { x: 4, y: 7 },
                      { x: 5, y: 11 },
                    ],
                    type: "scatter",
                    backgroundColor: "#FF6384",
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Daily Metrics (Scatter Chart)",
                  },
                },
                scales: {
                  x: {
                    // Additional x-axis options
                  },
                  y: {
                    // Additional y-axis options
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="about-footer">
        <div className="about-footer-content">
          <div className="about-footer-section contact-info">
            <p>
              <FontAwesomeIcon icon={faUndo} />
              <Link
                to="/Refund"
                className={window.location.hash === '#/Refund' ? 'active' : ''}
              >
                Cancellation and Refund
              </Link>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> Email:{" "}
              <a href="mailto:guruguesss@gmail.com">guruguesss@gmail.com</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faInstagram} /> Instagram:{" "}
              <a href="https://www.instagram.com/guruguesss" target="_blank" rel="noopener noreferrer">
                @guruguesss
              </a>
            </p>
          </div>
          <div className="terms-footer-section middle-links">
            <p>
              <FontAwesomeIcon icon={faBlog} />
              <Link
                to="/blogs"
                className={window.location.hash === '#/blogs' ? 'active' : ''}
              >
                Blog
              </Link>
            </p>
          </div>

          <div className="about-footer-section">
            <p>
              <FontAwesomeIcon icon={faFileAlt} />
              <Link
                to="/terms"
                className={window.location.hash === '#/terms' ? 'active' : ''}
              >
                Terms and Conditions
              </Link>
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> Contact Us:{" "}
              <a href="tel:9140293443">9140293443</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> Queries:{" "}
              <a href="tel:7460802449">7460802449</a>
            </p>
          </div>
        </div>
        <div className="about-footer-bottom">
          <p>Powered by: KNKN Engineering Works Pvt. Ltd.</p>
          <p>© 2024 Guruguess. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Mocktest;
