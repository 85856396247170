// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, get , ref } from 'firebase/database';

import { db } from "./firebase";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcku0Wr_z5l2P48WtP5aVqv3dQpeyc8tg",
  authDomain: "guruguess-5f034.firebaseapp.com",
  databaseURL: "https://guruguess-5f034-default-rtdb.firebaseio.com",
  projectId: "guruguess-5f034",
  storageBucket: "guruguess-5f034.appspot.com",
  messagingSenderId: "1056522278603",
  appId: "1:1056522278603:web:c38bb6f10b86eb979bad4e",
  measurementId: "G-M3NDSZ6WFH"
};
export {  ref, get };
export { database as db };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
export { database };